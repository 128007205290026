import { urlSettings } from "./../app/http-confing/settings/urlSettings";
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCCJKmGEWUicrb0Z5SEHGmberkkNJGq8ts",
    authDomain: "joyou-5a39d.firebaseapp.com",
    // databaseURL: "https://usoomat-f2825.firebaseio.com",
    projectId: "joyou-5a39d",
    storageBucket: "joyou-5a39d.appspot.com",
    messagingSenderId: "124836917499",
    appId: "1:124836917499:web:10ccf95d70903b3084405e",
    measurementId: "G-DCG85KW1MS",
  },
};

urlSettings.Settings = {
  apiProtocol: "https",
  apiHost: "api.coupon-wafy.com",
  apiEndPoint: "api",
  language: "ar",

  // apiProtocol: "https",
  // apiHost: "oula.firstcard.sa",
  // apiEndPoint: "api",
  // language: "ar",

  // apiProtocol: "http",
  // apiHost: "4388-197-58-102-117.eu.ngrok.io",
  // apiEndPoint: "api",
  // language: "ar",

  // apiPort: 5000,
  // apiProtocol: "http",
  // apiHost: "192.168.1.11",
  // apiEndPoint: "api",
  // language: "ar",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
