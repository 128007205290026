<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box" >
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-light-small.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.svg" alt="" height="17" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light-small.png" alt="" height="30" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.svg" alt="" height="50" />
          </span>
        </a>
      </div>


      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-xl-block">
        <div class="position-relative" *ngIf="canView">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'Search...' | translate }}"
            [(ngModel)]="keyword"
            (keyup.enter)="search()"
            [ngModelOptions]="{ standalone: true }"
          />
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <!-- <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          data-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          {{ "HEADER.MEGA_MENU" | translate }}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.APPLICATIONS.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.ECOMMERCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CALENDAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.EMAIL" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.PROJECTS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.TASKS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CONTACTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.EXTRA_PAGES.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.LIGHT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMPACT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.MAINTENANCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMING SOON" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.TIMELINE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.FAQ" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="assets/images/megamenu-img.png"
                      alt=""
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown"
        >
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          ngbDropdownToggle
        >
          <img
            *ngIf="flagvalue !== undefined"
            src="{{ flagvalue }}"
            alt="Header Language"
            height="16"
          />
          <span class="ms-1">{{ countryName }}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{ valueset }}"
            alt="Header Language"
            height="16"
          />
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          ngbDropdownToggle
        >
          <i class="bx bx-customize"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end"
          ngbDropdownMenu
        >
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a
                  class="dropdown-icon-item"
                  href="https://analytics.google.com/"
                >
                  <img src="assets/images/brands/analatics.png" alt="Github" />
                  <span>Google Analytics</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="https://clevertap.com/">
                  <img
                    src="assets/images/brands/clivertap.png"
                    alt="bitbucket"
                  />
                  <span>CleverTap</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="https://www.intercom.com/">
                  <img src="assets/images/brands/intercom.png" alt="dribbble" />
                  <span>Intercom</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-4">
                <a
                  class="dropdown-icon-item"
                  href="https://firebase.google.com/"
                >
                  <img src="assets/images/brands/firebase.png" alt="dropbox" />
                  <span>Firebase</span>
                </a>
              </div>
              <!-- <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    src="assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                  <span>Slack</span>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle
        >
          <i class="bx bx-bell bx-tada"></i>
          <span
            class="badge bg-danger rounded-pill"
            *ngIf="unreadNotifications$ | async as unreadNotifications"
            >{{ unreadNotifications }}</span
          >
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ "All Notifications" | translate }}</h6>
              </div>
              <div class="col-auto">
                <a
                  href="javascript: void(0);"
                  class="small"
                  (click)="markAllNotificationsAsRead()"
                >
                  {{ "Read All" | translate }}
                </a>
              </div>
            </div>
          </div>
          <ngx-simplebar
            style="position: relative; height: 230px"
            *ngIf="allNotifications.length"
          >
            <a
              href="javascript: void(0);"
              class="text-reset notification-item"
              *ngFor="let notification of allNotifications"
            >
              <div
                class="media mt-2"
                [ngStyle]="{
                  'background-color': !notification.read_at ? '#222252' : ''
                }"
              >

                <div
                  class="media-body"
                  (click)="
                    notificationTypes(
                      notification?.action_type,
                      notification?.action_id
                    )
                  "
                  (click)="markNotificationAsRead(notification.id)"
                >
                  <h6 class="mt-0 mb-1 text-danger">
                    {{ notification.title }}
                  </h6>
                  <div class="font-size-12 text-info">
                    <p class="mb-1">
                      {{ notification.body }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ notification.created_at_human_format }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <ngx-simplebar
            style="position: relative; height: 230px"
            *ngIf="!allNotifications.length"
          >
            <a href="javascript: void(0);" class="text-reset notification-item">
              <h3 class="text-center">
                {{ "No New Notifications" | translate }}
              </h3>
            </a>
          </ngx-simplebar>

          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-light btn-block text-center"
              href="javascript:void(0)"
              (click)="loadMoreNotifications()"
            >
              <i class="mdi mdi-arrow-down-circle me-1"></i>
              {{ "Show More" | translate }}
            </a>
          </div>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/user-avatar.png"
            [alt]="currentuser?.name"
          />
          <span class="d-none d-xl-inline-block ms-1">{{
            currentuser?.name
          }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item"
            [routerLink]="['/admin-profile']"
            *ngIf="currentuser.user_type == 'admin'"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ "Profile" | translate }}</a
          >

          <a
            class="dropdown-item d-block"
            href="javascript: void(0);"
            [routerLink]="['/settings']"
            *ngIf="currentuser.user_type == 'admin'"
          >
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i
            >{{ "Settings" | translate }}
          </a>

          <div
            class="dropdown-divider"
            *ngIf="currentuser.user_type == 'admin'"
          ></div>
          <a
            class="dropdown-item text-danger"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "LogOut" | translate }}</a
          >
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
