import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    isTitle: true,
    role: "admin",
  },
  {
    id: 2,
    label: "Home",
    icon: "bx-home-circle",
    link: "/dashboard",
    role: "admin",
  },

  {
    id: 29,
    label: "Stores",
    isTitle: true,
    role: "admin",
    permission: "view_stores",
  },
  {
    id: 30,
    label: "Stores",
    icon: "bx-store-alt",
    link: "/stores",
    role: "admin",
    permission: "view_stores",
  },

  {
    id: 10,
    label: "Offers",
    isTitle: true,
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 11,
    label: "Offers",
    link: "/offers",
    icon: "bx bxs-offer",
    role: "admin",
    permission: "view_offers",
  },

  {
    id: 12,
    label: "Categories",
    isTitle: true,
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 21,
    label: "Categories",
    link: "/categories",
    icon: "bx-book-open",
    role: "admin",
    permission: "view_categories",
  },

  {
    id: 8,
    label: "Users",
    isTitle: true,
    role: "admin",
    permission: "view_clients",
  },

  {
    id: 89,
    label: "Management",
    link: "/management",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_users",
  },

  {
    id: 9,
    label: "Users",
    link: "/users",
    icon: "bx bx-group",
    role: "admin",
    permission: "view_clients",
  },
  {
    id: 90,
    label: "App Intro Images",
    isTitle: true,
    role: "admin",
    permission: "view_sliders",
  },

  {
    id: 99,
    label: "Mobile Home Slider",
    link: "/mobilehome",
    icon: "bx bx bx-images",
    role: "admin",
    permission: "view_sliders",
  },

  {
    id: 121,
    label: "Questions Section",
    isTitle: true,
    role: "admin",
    permission: "view_questions",
  },

  {
    id: 121,
    label: "Questions Section",
    link: "/questions",
    icon: "bx bx-question-mark",
    role: "admin",
    permission: "view_questions",
  },

  {
    id: 124,
    label: "Tags",
    isTitle: true,
    role: "admin",
    permission: "view_tags",
  },

  {
    id: 124,
    label: "Tags",
    link: "/tags",
    icon: "bx bx-purchase-tag-alt",
    role: "admin",
    permission: "view_tags",
  },

  {
    id: 31,
    label: "Announcment",
    isTitle: true,
    role: "admin",
    permission: "view_ads",
  },
  {
    id: 32,
    label: "Announcment",
    icon: "bx-volume-full",
    link: "/ads",
    role: "admin",
    permission: "view_ads",
  },

  {
    id: 201,
    label: "Notification",
    isTitle: true,
    role: "admin",
    permission: "view_notifications",
  },
  {
    id: 201,
    label: "Notification",
    icon: "bx bx-bell",
    link: "/notification",
    role: "admin",
    permission: "view_notifications",
  },

  {
    id: 66,
    label: "Mobile Home",
    isTitle: true,
    role: "admin",
    permission: "edit_form_builder",
  },

  {
    id: 66,
    label: "Mobile Home",
    link: "/homeFilter",
    icon: "bx bx-filter-alt",
    role: "admin",
    permission: "edit_form_builder",
  },

  {
    id: 78,
    label: "Roles",
    isTitle: true,
    role: "admin",
    permission: "view_privileges",
  },

  {
    id: 78,
    label: "Roles",
    link: "/roles",
    icon: "bx bx-lock-alt",
    role: "admin",
    permission: "view_privileges",
  },
  {
    id: 3,
    label: "Pages",
    isTitle: true,
    role: "admin",
    permission: "view_pages",
  },

  {
    id: 4,
    label: "Pages",
    link: "/pages/list",
    icon: "bx-spreadsheet",
    role: "admin",
    permission: "view_pages",
  },
  {
    id: 5,
    label: "New Page",
    link: "/pages/create",
    icon: "bx-list-plus",
    role: "admin",
    permission: "create_page",
  },

  {
    id: 6,
    label: "Regions",
    isTitle: true,
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 7,
    label: "Countries",
    link: "/regions",
    icon: "bx-world",
    role: "admin",
    permission: "view_regions",
  },

  {
    id: 17,
    label: "Support",
    isTitle: true,
    role: "admin",
    permission: "view_support_messages_templates",
  },

  {
    id: 18,
    label: "Support",
    link: "/support",
    queryparams: { currentTap: 1 },
    icon: "bx-support",
    role: "admin",
    permission: "view_support_messages_templates",
  },
  {
    id: 5,
    label: "Socials",
    isTitle: true,
    role: "admin",
    permission: "view_socials",
  },
  {
    id: 6,
    label: "Socials",
    icon: "bx bxs-cog",
    link: "/socials",
    role: "admin",
    permission: "view_socials",
  },
];
